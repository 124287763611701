import utils from "@ms/nlib/utils";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Table } from "react-bootstrap";

import ThemeContext from "../contexts/ThemeContext";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const TableDetails = props =>
{
    const { isDark } = useContext(ThemeContext);
    const { children, title, large, rounded, shadow, className, ...other } = props;

    let classes = "";
    if(shadow)
        classes = utils.combineStrings(classes, "shadow");
    if(large)
        classes = utils.combineStrings(classes, "msp-table-large");
    if(rounded)
        classes = utils.combineStrings(classes, "msp-table-rounded");
    if(utils.hasValue(className))
        classes = utils.combineStrings(classes, className);

    const dark = isDark();

    return (
        <Table striped bordered={false} variant={dark ? "dark" : "light"} className={classes} {...other}>
            {
                utils.hasValue(title) &&
                <thead className={dark ? "thead-dark" : "thead-light"}>
                    <tr>
                        <th colSpan={2}>{title}</th>
                    </tr>
                </thead>
            }
            <tbody>
                {children}
            </tbody>
        </Table>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

TableDetails.propTypes =
{
    children: PropTypes.any,
    title: PropTypes.any,
    large: PropTypes.bool,
    rounded: PropTypes.bool,
    shadow: PropTypes.bool,
    className: PropTypes.string
};

TableDetails.defaultProps =
{
    large: false,
    rounded: true,
    shadow: false
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

TableDetails.Pair = props =>
{
    return (
        <tr>
            <td className="text-right text-primary" style={{width: "50%", whiteSpace: "nowrap"}}><b>{props.name}</b></td>
            <td className="text-left">{props.value}</td>
        </tr>
    );
};

TableDetails.Pair.displayName = "TableDetails.Pair";

TableDetails.Pair.propTypes =
{
    name: PropTypes.any,
    value: PropTypes.any
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

TableDetails.Value = props =>
{
    return (
        <tr>
            <td colSpan={2} className="text-left">{props.value}</td>
        </tr>
    );
};

TableDetails.Value.displayName = "TableDetails.Value";

TableDetails.Value.propTypes =
{
    value: PropTypes.any
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default TableDetails;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
