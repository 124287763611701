import PropTypes from "prop-types";
import React from "react";

import { Card } from "react-bootstrap";

import LoadIcon from "./LoadIcon";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const LoadIndicator = props =>
{
    return (
        <Card>
            <Card.Body>
                {props.title} <LoadIcon/>
            </Card.Body>
        </Card>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

LoadIndicator.propTypes =
{
    title: PropTypes.string
};

LoadIndicator.defaultProps =
{
    title: "Loading"
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default LoadIndicator;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
