import React from "react";
import { MdWarning } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";

import options from "../../client/options";
import { largeIcon } from "../controls/Icon";
import Label from "../controls/Label";
import { Warning } from "../controls/Message";
import Content from "../layout/Content";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const WarningIcon = largeIcon(MdWarning);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const NotFound = props =>
{
    const location = useLocation();

    const message = <>
        The requested url {`'${location.pathname}'`} could not be found on this server.<br/>
        Please click <Link to={options.links.home}>here</Link> to return to the homepage.
    </>;

    const body = (
        <Warning>
            <Warning.Heading><Label icon={WarningIcon}>Location not found</Label></Warning.Heading>
            <hr/>
            <div>
                <p className="mb-0">{message}</p>
            </div>
        </Warning>
    );

    return (
        <Content center sidebarVisible={false}>
            {body}
        </Content>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default NotFound;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
