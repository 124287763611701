import utils from "@ms/nlib/utils";
import PropTypes from "prop-types";
import React from "react";
import { MdSearch } from "react-icons/md";
import { withRouter } from "react-router-dom";

import options from "../../../client/options";
import mss_utils from "../../../common/mss_utils";
import AsyncComponent from "../../components/AsyncComponent";
import { normalIcon } from "../../controls/Icon";
import { Error } from "../../controls/Message";
import TableDetails from "../../controls/TableDetails";
import ToolbarButton from "../../controls/ToolbarButton";
import ToolbarSearch from "../../controls/ToolbarSearch";
import Content from "../../layout/Content";
import Toolbar from "../../layout/Toolbar";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const SearchIcon = normalIcon(MdSearch);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class LicensesSearchPage extends AsyncComponent
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            loading: false,
            licenseData: null,
            error: null,
            searchEmpty: true
        };
        this.inputSystemID = React.createRef();
        this.urlParams = mss_utils.decodeUrlParams(this.props.location.search);
    }

    componentDidMount()
    {
        if(utils.hasValue(this.urlParams.system_id))
        {
            this.inputSystemID.current.value = this.urlParams.system_id;
            this.searchSystemID();
        }

        this.updateUI();

        this.inputSystemID.current.focus();
    }

    render()
    {
        // Only available in function components
        //const pageParams = useParams();

        const licenseData = this.state.licenseData;

        let body = null;
        if(this.state.error)
        {
            body = <Error text={this.state.error}/>;
        }
        else if(this.state.licenseData != null)
        {
            const data = this.state.licenseData;
            body = (
                <TableDetails large title="License details:">
                    <TableDetails.Pair name="Manufacturing ID:" value={data.manufacturing_id}/>
                    <TableDetails.Pair name="System ID:" value={data.system_id}/>
                    <TableDetails.Pair name="Serial number, Order ID:" value={data.order_id}/>
                    <TableDetails.Pair name="License code:" value={data.code}/>
                    <TableDetails.Pair name="Expiration:" value={data.expiration !== 0 ? mss_utils.formatDate(data.expiration) : "None"}/>
                    <TableDetails.Pair name="Spitting License code:" value={data.spitting_code}/>
                    <TableDetails.Pair name="Spitting Expiration:" value={data.spitting_code_exp !== 0 ? mss_utils.formatDate(data.spitting_code_exp) : "None"}/>
                </TableDetails>
            );
        }

        const toolbarCommands = (
            <>
                <ToolbarSearch
                    disabled={this.state.loading}
                    empty={this.state.searchEmpty}
                    placeholder="Enter SystemID"
                    inputRef={this.inputSystemID}
                    onChange={this.updateUI}
                    onClear={this.onClear}/>
                <ToolbarButton
                    disabled={this.state.loading || this.state.searchEmpty}
                    type="submit"
                    className="ml-sm-2"
                    onClick={this.searchSystemID}>Search <SearchIcon/></ToolbarButton>
            </>
        );
        const toolbar = <Toolbar title={mss_utils.formatPageTitle("Find License", null, null)} commands={toolbarCommands} loading={this.state.loading}/>;

        return (
            <Content sidebarVisible={false} margin center={licenseData == null} toolbar={toolbar}>
                {body}
            </Content>
        );
    }

    onClear = () =>
    {
        this.inputSystemID.current.value = "";
        this.updateUI();
        this.inputSystemID.current.focus();
    };

    updateUI = () =>
    {
        this.setState({ searchEmpty: this.inputSystemID.current && (this.inputSystemID.current.value.length === 0) });
    };

    setLoading = (loading, c = null) =>
    {
        if(loading)
        {
            this.setState({
                loading,
                licenseData: null,
                error: null,
            }, c);
        }
        else
        {
            this.setState({ loading }, c);
        }
    };

    setLicenseData = licenseData =>
    {
        this.setState({
            licenseData,
            error: null
        });
    };

    setError = error =>
    {
        this.setState({
            licenseData: null,
            error
        });
    };

    searchSystemID = () =>
    {
        this.setLoading(true);

        const systemID = this.inputSystemID.current.value;

        fetch(options.api.jpk.license(systemID), { signal: this.abortSignal })
            .then(response =>
            {
                if(response.ok)
                {
                    response.json()
                        .then(licenseData =>
                        {
                            this.setLicenseData(licenseData);

                            this.inputSystemID.current.value = "";
                            this.updateUI();
                        })
                        .catch(() => this.setError("Failed load license (invalid server response)"));
                }
                else
                {
                    if(response.status !== 404)
                        this.setError(`Failed load license (#${response.status})`);
                    else
                        this.setError("License not found!");
                }
            })
            .catch(err => this.setError(`Failed load license (${utils.getErrorMessage(err)})`))
            .finally(() =>
            {
                this.setLoading(false);

                this.inputSystemID.current.focus();
            });
    };
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

LicensesSearchPage.propTypes =
{
    location: PropTypes.object
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default withRouter(LicensesSearchPage);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
