import utils from "@ms/nlib/utils";
import PropTypes from "prop-types";
import React from "react";

import mss_constants from "../../common/mss_constants";
import { SessionProvider } from "../contexts/SessionContext";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const createSessionContext = () =>
{
    // TODO: use local storage
    const userOptions = {};

    const getLanguage = () => utils.arrayGetFirstDefined([navigator?.language, mss_constants.localization.language_default]);
    const getOption = (id, defValue = undefined) => utils.objectGetKey(userOptions, id, defValue);

    return {
        getLanguage,
        getOption
    };
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const SessionManager = props =>
{
    return (
        <SessionProvider value={createSessionContext()}>
            {props.children}
        </SessionProvider>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

SessionManager.propTypes =
{
    user: PropTypes.object,
    children: PropTypes.any,
    onRefresh: PropTypes.func
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default SessionManager;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
