import utils from "@ms/nlib/utils";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import SideMenu from "../controls/SideMenu";
import sidebar from "./Sidebar.slice";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const SidebarWrapper = styled.div`
    position: fixed;
    top: 57px;                  // TODO: this should be computed with bootstrap variables
    bottom: 0;
    margin-top: -1px;           // Hides the toolbar background border
    z-index: 1040;              // Bootstrap's modal z-index is 1050 but it must be lower than 1041 because of backdrop's shadow
`;

const SidebarBackground = styled.div`
    background-color: ${props => props.theme.colors.sidebar_background};
    border-right: 1px solid ${props => props.theme.colors.border};
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;               
    //width: 250px !important;

    &::-webkit-scrollbar {
        width: 0.5em;
    }
    
    &::-webkit-scrollbar-track {
        background-color: ${props => props.theme.colors.border};      
        border-top: 1px solid ${props => props.theme.colors.border};
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: ${props => props.theme.colors.dark};
        
        &:hover {
            background-color: white;
        }
    }    
`;
/*
// Do not extend SidebarContent from styled(SideMenu) to avoid glitches
const SidebarContent = styled.div`
    border-right: 1px solid ${props => props.theme.colors.border};
    height: 100%;
`;
*/
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const Sidebar = props =>
{
    const menu = props.menu;
    menu.forEach(m =>
    {
        if(utils.hasValue(m.id) && utils.hasValue(m.items))
        {
            // Menu group

            m.open = props.getMenuOpen(m.id);
            m.setOpen = open => props.setMenuOpen(m.id, open);
        }
        else
        {
            // Menu item

            //if(utils.hasValue(m.url))
            //    m.icon = icons.get(m.url);
        }
    });

    return (
        <SidebarWrapper className="msp-transition" style={props.style}>
            <SidebarBackground>
                {
                    /*
                    <SidebarContent>
                        <SideMenu menu={menu}/>
                    </SidebarContent>
                    */
                }
                <SideMenu menu={menu}/>
            </SidebarBackground>
        </SidebarWrapper>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

Sidebar.propTypes =
{
    menu: PropTypes.arrayOf(PropTypes.object),
    getMenuOpen: PropTypes.func.isRequired,
    setMenuOpen: PropTypes.func.isRequired,
    sidebar: PropTypes.object.isRequired,
    style: PropTypes.object
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const mapStateToProps = state => ({
    sidebar: state.ui.layout.sidebar,
    getMenuOpen(id)
    {
        return sidebar.methods.getMenuOpen(this.sidebar, id);
    }
});

const mapDispatchToProps = dispatch => ({
    setMenuOpen: (id, open) => dispatch(sidebar.actions.setMenuOpen({id, open}))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
