import PropTypes from "prop-types";
import React, { useContext } from "react";

import ThemeContext from "../contexts/ThemeContext";
import Button from "./Button";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const ToolbarButton = props =>
{
    const { isDark } = useContext(ThemeContext);

    let { children, variant = "primary", ...other } = props;

    if(!isDark())
    {
        switch(variant)
        {
        case "primary":
            variant = "outline-light";
            break;

        case "secondary":
            variant = "secondary-light";
            break;
        /*
        case "danger":
            variant = "outline-light";
            break;
        */
        }
    }

    // lineHeight=1.5 fixes height with ToolbarSearch
    return <Button variant={variant} {...other} style={{ lineHeight: "1.5"}}>{children}</Button>;
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

ToolbarButton.propTypes =
{
    children: PropTypes.any,
    variant: PropTypes.string
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default ToolbarButton;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
