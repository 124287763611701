import React from "react";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class AsyncComponent extends React.Component
{
    constructor(props)
    {
        super(props);

        this._abortController = null;
    }

    get abortSignal()
    {
        // TODO: multiple signals support: getSignal = name => ...
        // abortSignal -> this.getSignal("");

        if(this._abortController == null)
            this._abortController = new AbortController();

        return this._abortController.signal;
    }

    componentWillUnmount()
    {
        // Override the setState method to avoid React warnings "Can't perform a React state update on an unmounted component..." on unmounted components.
        this.setState = (state, callback) => {};
        // Raise the abort signal
        this.__raiseAbort();
    }

    __raiseAbort = () =>
    {
        if(this._abortController != null)
        {
            this._abortController.abort();
            this._abortController = null;
        }
    };
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default AsyncComponent;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
