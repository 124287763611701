import React from "react";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const NotificationsContext = React.createContext();

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default NotificationsContext;

const NotificationsProvider = NotificationsContext.Provider;
const NotificationsConsumer = NotificationsContext.Consumer;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// eslint-disable-next-line react/display-name
const withNotifications = Component => props =>
{
    return (
        <NotificationsConsumer>
            {
                context => <Component {...props} notifications={context}/>
            }
        </NotificationsConsumer>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export { NotificationsProvider, NotificationsConsumer, withNotifications };

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
