import { combineReducers } from "@reduxjs/toolkit";

import ui from "../ui/reducers";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default combineReducers({
    ui

    // TODO: add "data" root node for each specific portal's submodule
});

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
