import "./SideMenu.scss";

import utils from "@ms/nlib/utils";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import {
    Badge,
    Collapse,
    Nav
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import styled from "styled-components";

import ThemeContext from "../contexts/ThemeContext";
import { useLocationUrl } from "../middlewares/location";
import { normalIcon } from "./Icon";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const textLight = "text-white";
const textDark = "";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const SideMenuNav = styled(Nav)`
    & .nav-link.active::before
    {
        content: "";
        left:0;
        height: 24px;
        border-left: 4px solid white;
        position: absolute;
    }

    // IMPORTANT: do NOT use this version with "position: absolute" to avoid arrow to be over the scrollbar
    /*
    & .nav-link.active::after
    {
        content: "";
        right: -1px;	// hide the sidemenu right edge
        height: 18px;
        position: absolute;
        margin-top: 2px;
        border-bottom: 10px solid transparent;
        border-right: 10px solid ${props => props.theme.colors.background};
        border-top: 10px solid transparent;
    }
    */

    /*    
    & .nav-link
    {
        padding-right: 0px !important;
    }

    & .active::after
    {
        content: "";
        right: -1px;
        height: 18px;
        margin-top: 2px;
        border-bottom: 10px solid transparent;
        border-right: 10px solid ${props => props.theme.colors.background};
        border-top: 10px solid transparent;
        float: right;
        position: relative;
    }
    */
`;

const SideMenuSubitem = styled.div`
    background-color: ${props => props.theme.colors.softdark};
`;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const SideMenuLink = props =>
{
    const locationUrl = useLocationUrl();

    const { item, dark, nested } = props;
    const style = nested ? { paddingLeft: normalIcon.size } : null;
    const label = <span style={style}>
        {item.icon ? <item.icon/> : null} {item.title}{item.badge && <Badge>{item.badge}</Badge>}
    </span>;

    if(item.extern)
    {
        return (
            <a className={utils.combineStrings("msp-sidemenu-link nav-link", dark ? textDark : textLight)} href={item.url} rel="noopener noreferrer" target="_blank">
                {label}
            </a>
        );
    }

    let linkClass = "msp-sidemenu-link";
    if(locationUrl === item.url)
        // SideMenuLink items with query params fix
        linkClass = utils.combineStrings(linkClass, "active");

    return (
        <LinkContainer to={item.url} exact>
            <Nav.Link className={utils.combineStrings(linkClass, dark ? textDark : textLight)}>
                {label}
            </Nav.Link>
        </LinkContainer>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

SideMenuLink.propTypes =
{
    dark: PropTypes.bool,
    item: PropTypes.object,
    nested: PropTypes.bool
};

SideMenuLink.defaultProps =
{
    nested: false
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const SideMenuItem = props =>
{
    return (
        <Nav.Item>
            <SideMenuLink item={props.item} dark={props.dark}/>
        </Nav.Item>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

SideMenuItem.propTypes =
{
    dark: PropTypes.bool,
    item: PropTypes.object
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const SideMenuSubItem = props =>
{
    const { item, dark } = props;
    if(!utils.hasValue(item))
        return <hr className="msp-sidemenu-separator"/>;

    return (
        <SideMenuSubitem>
            <SideMenuLink item={item} dark={dark} nested/>
        </SideMenuSubitem>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

SideMenuSubItem.propTypes =
{
    dark: PropTypes.bool,
    item: PropTypes.object
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const SideMenuGroup = props =>
{
    const { dark, item } = props;
    const items = item.items;

    // Each subItem must be wrapped into a div to avoid LinkContainer's active problems with Collapse
    const subItems = items.map((item, i) => <SideMenuSubItem key={i} item={item} dark={dark}/>);

    const open = item.open;
    const arrow = <span style={{float: "right"}}>{open ? <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>}</span>;

    return (
        <Nav.Item>
            <Nav.Link onClick={() => item.setOpen(!open)} className={dark ? textDark : textLight}>
                {item.icon ? <item.icon/> : null} {item.title}{item.badge && <Badge>{item.badge}</Badge>}{arrow}
            </Nav.Link>
            <Collapse in={open}>
                <div>
                    {subItems}
                </div>
            </Collapse>
        </Nav.Item>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

SideMenuGroup.propTypes =
{
    dark: PropTypes.bool,
    item: PropTypes.object
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const renderMenu = (dark, menu, onSelect) =>
{
    const navItems = menu.map((item, i) => item.items ? <SideMenuGroup key={i} dark={dark} item={item}/> : <SideMenuItem key={i} dark={dark} item={item}/>);

    return (
        <SideMenuNav variant="pills" className="msp-sidemenu" onSelect={onSelect}>
            {navItems}
        </SideMenuNav>
    );
};

const SideMenu = props =>
{
    const { isDark } = useContext(ThemeContext);

    const content = renderMenu(isDark(), props.menu, props.onItemSelected);

    return (
        <>
            {props.header}
            {content}
        </>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

SideMenu.propTypes =
{
    header: PropTypes.node,
    menu: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            url: PropTypes.string,
            icon: PropTypes.any,
            items: PropTypes.arrayOf(PropTypes.any),
            open: PropTypes.bool
        })
    ),
    footer: PropTypes.node,
    onItemSelected: PropTypes.func
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default SideMenu;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
