import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Sidebar from "./Sidebar";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const ContentBackground = styled.div`
    background-color: ${props => props.theme.colors.background};
    display: flex;
    flex-direction: row;    
    flex: 1;    
`;

const ContentChildren = styled.div`
    display: flex;
    flex-direction: column;    
    flex: 1;
`;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const Content = props => {
    const sidebarMenu = [];

    // Same as:
    //      const mapStateToProps = state => ({ sidebar: state.ui.layout.sidebar });
    //      export default connect(Content);
    const sidebar = useSelector(state => state.ui.layout.sidebar);

    const sidebarVisible = sidebar.visible && (sidebarMenu.length > 0);
    const sidebarStyle = sidebarVisible ? { width: props.sidebarWidth, left: 0 } : { width: props.sidebarWidth, left: `-${props.sidebarWidth}` };

    let childrenClass = "msp-transition";
    if(props.center)
        childrenClass += " msp-center";

    const hasSidebar = props.sidebarVisible && sidebarVisible;
    const childrenStyle = hasSidebar ? { marginLeft: props.sidebarWidth } : { marginLeft: 0 };
    const children = props.margin ? <div className="m-3">{props.children}</div> : props.children;

    const toolbar = props.toolbar ? React.cloneElement(props.toolbar, { toggle: (sidebarMenu.length > 0) }) : null;

    return (
        <>
            {toolbar}
            <ContentBackground>
                {props.sidebarVisible ? <Sidebar style={sidebarStyle} menu={sidebarMenu} /> : null}
                <ContentChildren className={childrenClass} style={childrenStyle}>
                    {children}
                </ContentChildren>
            </ContentBackground>
        </>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

Content.propTypes =
{
    center: PropTypes.bool,
    margin: PropTypes.bool,
    children: PropTypes.any,
    sidebarMenu: PropTypes.arrayOf(PropTypes.object),
    sidebarVisible: PropTypes.bool,
    sidebarWidth: PropTypes.string,
    toolbar: PropTypes.node
};

Content.defaultProps =
{
    center: false,
    margin: false,
    sidebarVisible: true,
    sidebarWidth: "250px"
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default Content;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
