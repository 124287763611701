import { combineReducers } from "@reduxjs/toolkit";

import layout from "../layout/reducers";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default combineReducers({
    layout
});

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
