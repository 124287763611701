import moment from "moment";
import * as yup from "yup";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

moment.fn.fromNowOrNow = function(a)
{
    if(Math.abs(moment().diff(this)) < 60000)  // 1 minute before or after now
        return "now";

    return this.fromNow(a);
};

yup.addMethod(yup.string, "unique", function(message, list, mapper = a => a)
{
    return this.test("unique", message, function(value)
    {
        const { path, createError } = this;

        if(list.includes(mapper(value)))
            return createError({ path, message });

        return true;
    });
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
