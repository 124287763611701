import utils from "@ms/nlib/utils";
import moment from "moment";
import qs from "qs";

import mss_constants from "./mss_constants";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const mss_utils =
{
    splitAuthGroups: v =>
    {
        if(utils.isStringEmpty(v))
            return [];

        return v.split(",").map(e => utils.trimString(e)).filter(e => !utils.isStringEmpty(e));
    },

    formatDate: ts => ts !== 0 ? moment.unix(ts).format("DD/MM/YYYY") : "",
    formatTimeFromNow: ts => ts !== 0 ? moment.unix(ts).fromNowOrNow() : "",
    formatPageTitle: (name, details = null, group = null) =>
    {
        let title = "MS Support";
        if(utils.hasValue(group))
        {
            title += ` - ${group}`;

            if(utils.hasValue(name))
                title += " > ";
        }
        else if(utils.hasValue(name))
        {
            title += " - ";
        }

        title += utils.valueOrDefault(name, "");

        if(utils.hasValue(details))
            title += `: ${utils.toString(details)}`;

        return title;
    },

    utcToMoment: ts => moment.unix(ts),

    createError: e => new Error(utils.getErrorMessage(e)),

    requireCondition: (condition, message) =>
    {
        if(!condition)
            throw mss_utils.createError(message);
    },

    requireParam: (param, name, func = null) =>
    {
        if(!utils.hasValue(param))
            throw mss_utils.createError(`invalid param '${name}'${func ? ` in function '${func}'` : ""}`);
    },

    parseUsers: (records, asObject = true) =>
    {
        const users = {};

        if(utils.hasValue(records))
        {
            records.forEach(u => {
                let user = users[u.id];
                if(!utils.hasValue(user))
                {
                    user = { email: u.email, language: mss_utils.languageToCode(u.language), options: utils.jsonDecode(u.options), profiles: [] };
                    if(!asObject)
                        user.id = u.id;
                    if(utils.hasValue(u.enabled))
                        user.enabled = utils.boolCast(u.enabled);

                    users[u.id] = user;
                }

                if(utils.hasValue(u.id_pf))
                    user.profiles.push(u.id_pf);
            });
        }

        if(asObject)
            return users;

        return Object.values(users);
    },

    encodeUrl: (url, params = {}) =>
    {
        const args = qs.stringify(params, { encode: true, skipNulls: true });
        if(utils.isStringEmpty(args))
            return url;

        return url + "?" + args;
    },
    decodeUrlParams: (search, ignoreQueryPrefix = true) => qs.parse(search, { ignoreQueryPrefix }),

    testGroupsAccess: (required, provided, any = true) =>
    {
        required = utils.valueOrDefault(required, []);
        if(!utils.isArray(required))
            required = [required];
        if(required.length === 0)
            return true;

        provided = utils.valueOrDefault(provided, []);
        if(!utils.isArray(provided))
            provided = [provided];

        return any ? required.some(g => provided.includes(g)) : required.every(g => provided.includes(g));
    },

    testAreaAccess: (area, level, provided) => mss_utils.testAreasAccess(utils.hasValue(area) ? { [area] : level } : null, provided),
    testAreasAccess: (required, provided, any = false) =>
    {
        required = utils.valueOrDefault(required, {});
        const requiredEntries = Object.entries(required);
        if(requiredEntries.length === 0)
            return true;

        provided = utils.valueOrDefault(provided, {});

        let anyMatch = false;

        const result = requiredEntries.reduce((accumulator, [area, level]) =>
        {
            const userLevel = provided[area];
            if(!utils.hasValue(userLevel) || userLevel < level)
                accumulator++;
            else
                anyMatch = true;

            return accumulator;
        }, 0);

        return any ? anyMatch : result === 0;
    },

    languageToCode: language =>
    {
        if(utils.isStringEmpty(language))
            return undefined;

        // it, IT, ITA matching
        if(utils.startsWithIgnoreCase(language, "IT"))
            return mss_constants.localization.language_it;

        // ...

        return mss_constants.localization.language_en;
    },

    codeToLanguage: code =>
    {
        if(utils.isStringEmpty(code))
            return undefined;

        if(utils.startsWithIgnoreCase(code, "it"))
            return "ITA";

        // ...

        return "ENG";
    }
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default mss_utils;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
