import "../ui/styles/index.scss";

import utils from "@ms/nlib/utils";
import { configureStore } from "@reduxjs/toolkit";
import React from "react";
import { Provider } from "react-redux";

import "../common/mss_extensions";
import rootReducer from "../reducers";
import Root from "./layout/Root";
import routes from "./routes";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const reduxStore = configureStore({
    reducer: rootReducer,
    //middleware: getDefaultMiddleware => getDefaultMiddleware().concat(logger)
});

const getReduxStore = () =>
{
    if(!utils.isBrowser())
        return reduxStore;

    // Workaround for Redux/Hot Module Replacement (preserve the store into the window object)

    if(window.reduxStore == null)
        window.reduxStore = reduxStore;
    //else if(utils.isDevelopmentEnvironment())
    //  window.reduxStore.replaceReducer(rootReducer);

    return window.reduxStore;
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const App = () =>
{
    return (
        <Provider store={getReduxStore()}>
            <Root routes={routes}/>
        </Provider>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default App;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
