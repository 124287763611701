import utils from "@ms/nlib/utils";
import { MdAssignment } from "react-icons/md";

import { normalIcon } from "../ui/controls/Icon";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const API_PREFIX = "/api";         // API must be prefixed with full server url
const API_JPK_PREFIX = API_PREFIX + "/jpk";
const JPK_PREFIX = "/jpk";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const g_options =
{
    api:
    {
        jpk:
        {
            license: systemID => `${API_JPK_PREFIX}/license?system_id=${systemID}`
        },
    },

    language:
    {
        debug: utils.boolCast(process.env.REACT_APP_LANGUAGE_DEBUG, utils.isDevelopmentEnvironment())
    },

    links:
    {
        home: "/",
        jpk:
        {
            licenses_search: JPK_PREFIX + "/licenses/search",
        },
    },

    params:
    {
        next: "next"
    }
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default g_options;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const icons =
{
    [g_options.links.jpk.licenses_search]: normalIcon(MdAssignment),
};

const getIcon = link =>
{
    if(!utils.hasValue(link))
        return null;

    // Find exact match

    const icon = icons[link];
    if(utils.hasValue(icon))
        return icon;

    // Url with params support (i.e.: /machine/:id)

    for(let k in icons)
    {
        if(link.startsWith(k))
            return icons[k];
    }

    return null;
};

const getLocationIcon = () => getIcon(window?.location?.pathname);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export { getIcon, getLocationIcon };

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
