import utils from "@ms/nlib/utils";
import React from "react";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const mapKey = (e, callback, keyCode, ...args) => e.keyCode === keyCode ? callback(...args) : undefined;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const helpers =
{
    mapEnterKey: (e, callback, ...args) => mapKey(e, callback, 13, ...args),
    mapKeyEsc: (e, callback, ...args) => mapKey(e, callback, 27, ...args),
    createReactElement: (Element, props = undefined) => utils.hasValue(Element) ? (React.isValidElement(Element) ? Element : <Element {...props}/>) : null
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default helpers;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
