import utils from "@ms/nlib/utils";
import PropTypes from "prop-types";
import React from "react";

import LoadIcon from "../controls/LoadIcon";
import Tooltip from "./Tooltip";
import helpers from "../../ui/helpers";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const Label = props =>
{
    const { busy, className, children, icon, tooltip, tooltipPlacement, ...other } = props;

    let IconComponent = icon;
    if(!utils.hasValue(IconComponent) && busy)
        IconComponent = LoadIcon;

    let separator = null;
    let left = null;
    if(utils.hasValue(IconComponent))
    {
        separator = utils.hasValue(children) ? " " : "";
        left = helpers.createReactElement(IconComponent, { className: busy ? "msp-spin" : null });
    }

    const content = <>{left}{separator}{children}</>;
    const body = utils.hasValue(props.as) ? <props.as className={className} {...other}>{content}</props.as> : <span className={className} {...other}>{content}</span>;

    if(!utils.hasValue(tooltip))
        return body;

    return (
        <Tooltip title={tooltip} placement={tooltipPlacement}>
            {body}
        </Tooltip>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

Label.propTypes =
{
    as: PropTypes.any,
    busy: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.any,
    icon: PropTypes.any,
    tooltip: PropTypes.any,
    tooltipPlacement: PropTypes.string
};

Label.defaultProps =
{
    busy: false
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default Label;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
