////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const LANGUAGE_EN = "en";
const LANGUAGE_IT = "it_IT";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const mss_constants =
{
    localization:
    {
        language_default: LANGUAGE_EN,

        language_en: LANGUAGE_EN,
        language_it: LANGUAGE_IT,
    },

    options:
    {
        option_theme: "d196b0d0-d848-4770-8bf6-d9eb9c6b5b61"
    },

    themes:
    {
        theme_dark: "dark",
        theme_light: "light"
    }
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default mss_constants;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
