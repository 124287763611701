import utils from "@ms/nlib/utils";
import PropTypes from "prop-types";
import React from "react";
import { Button as BootstrapButton } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import LoadIcon from "../controls/LoadIcon";
import Tooltip from "./Tooltip";
import helpers from "../../ui/helpers";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const Button = props =>
{
    const { busy, disabled, href, url, className, children, hideFocus, icon, tooltip, tooltipPlacement, ...other } = props;

    let IconComponent = icon;
    if(!utils.hasValue(IconComponent) && busy)
        IconComponent = LoadIcon;

    let separator = null;
    let right = null;
    if(utils.hasValue(IconComponent))
    {
        separator = utils.hasValue(children) ? " " : "";
        right = helpers.createReactElement(IconComponent, { className: busy ? "msp-spin" : null });
    }

    let body = <BootstrapButton
        href={url}
        disabled={disabled}
        className={utils.combineStrings(className, hideFocus ? "msp-hide-focus" : null)}
        {...other}>
        {children}{separator}{right}
    </BootstrapButton>;

    if(utils.hasValue(href))
        body = <LinkContainer to={href}>{body}</LinkContainer>;

    // IMPORTANT: disabled test fixes a bug where once a button (enabled) with a tooltip displayed is disabled (i.e. due to a state change), the tooltip doesn't disappear
    if(!utils.hasValue(tooltip) || disabled)
        return body;

    return (
        <Tooltip title={tooltip} placement={tooltipPlacement}>
            {body}
        </Tooltip>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

Button.propTypes =
{
    busy: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.any,
    hideFocus: PropTypes.bool,
    href: PropTypes.string,
    url: PropTypes.string,
    icon: PropTypes.any,
    tooltip: PropTypes.any,
    tooltipPlacement: PropTypes.string
};

Button.defaultProps =
{
    busy: false,
    hideFocus: true
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default Button;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
