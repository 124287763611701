import utils from "@ms/nlib/utils";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Toast } from "react-bootstrap";
import {
    MdDone,
    MdErrorOutline,
    MdInfoOutline,
    MdWarning
} from "react-icons/md";
import { ToastContainer, toast, Bounce } from "react-toastify";

import { NotificationsProvider } from "../contexts/NotificationsContext";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const NOTIFICATION_TYPE_INFO = "info";
const NOTIFICATION_TYPE_SUCCESS = "success";
const NOTIFICATION_TYPE_WARNING = "warning";
const NOTIFICATION_TYPE_ERROR = "error";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const notificationOptions = {
    position: toast.POSITION.BOTTOM_RIGHT,
    //autoClose: false,
    autoClose: 4000,
    closeButton: false
};

const notificationStyles = {
    [NOTIFICATION_TYPE_INFO]: {
        background: "bg-primary",
        border: "border-primary",
        text: "text-primary",
        icon: <MdInfoOutline/>
    },

    [NOTIFICATION_TYPE_SUCCESS]: {
        background: "bg-success",
        border: "border-success",
        text: "text-success",
        icon: <MdDone/>
    },

    [NOTIFICATION_TYPE_WARNING]: {
        background: "bg-warning",
        border: "border-warning",
        text: "text-warning",
        icon: <MdWarning/>
    },

    [NOTIFICATION_TYPE_ERROR]: {
        background: "bg-danger",
        border: "border-danger",
        text: "text-danger",
        icon: <MdErrorOutline/>
    }
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const showNotification = (type, notification, title = "") =>
{
    const nofificationStyle = notificationStyles[type];

    let content = "";
    let options = null;

    if(utils.isString(notification))
    {
        content = notification;
    }
    else
    {
        if(utils.hasValue(notification.title))
            title = notification.title;
        content = notification.content;
        options = notification.options;
    }

    const notificationComponent = (
        <Toast className={`border ${nofificationStyle.border}`}>
            <Toast.Header closeButton={false} className={`text-white ${nofificationStyle.background}`}>
                {nofificationStyle.icon}&nbsp;<strong className="mr-auto">{title}</strong>
                <small>{moment().format("HH:mm")}</small>
            </Toast.Header>
            <Toast.Body className={nofificationStyle.text}>{content}</Toast.Body>
        </Toast>
    );

    toast(notificationComponent, Object.assign({}, notificationOptions, options));
};

const notificationsContext = {
    info: notification => showNotification(NOTIFICATION_TYPE_INFO, notification, "Info"),
    success: notification => showNotification(NOTIFICATION_TYPE_SUCCESS, notification, "Success"),
    warning: notification => showNotification(NOTIFICATION_TYPE_WARNING, notification, "Warning"),
    error: notification => showNotification(NOTIFICATION_TYPE_ERROR, notification, "Error")
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const NotificationsManager = props =>
{
    return (
        <NotificationsProvider value={notificationsContext}>
            {props.children}
            <ToastContainer hideProgressBar transition={Bounce}/>
        </NotificationsProvider>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

NotificationsManager.propTypes =
{
    children: PropTypes.any
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default NotificationsManager;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
