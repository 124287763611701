import PropTypes from "prop-types";
import React from "react";
import { Overlay, Popover } from "react-bootstrap";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class OverlaySticky extends React.Component
{
    constructor(props)
    {
        super();

        this._child = null;
        this._delay = OverlaySticky.normalizeDelay(props.delay);

        this.state =
        {
            overlayVisible: false,
            hoverChild: false,
            hoverOverlay: false
        };
    }

    componentWillUnmount()
    {
        if(this.setTimeoutConst)
            clearTimeout(this.setTimeoutConst);
    }

    render()
    {
        let { overlay, children, placement } = this.props;

        const child = React.Children.map(children, child => (
            React.cloneElement(child,
                {
                    onMouseEnter: () => this.updateVisibility(true, false),
                    onMouseLeave: () => this.updateVisibility(false, this.state.hoverOverlay),
                    onClick: this.onChildClick,
                    ref: node =>
                    {
                        this._child = node;
                        const { ref } = child;
                        if(typeof ref === "function")
                            ref(node);
                    }
                })
        ))[0];

        return (
            <>
                <Overlay show={this.state.overlayVisible} placement={placement} target={this._child} shouldUpdatePosition={true}>
                    <Popover onMouseEnter={() => this.updateVisibility(false, true)} onMouseLeave={() => this.updateVisibility(this.state.hoverChild, false)}>
                        {overlay}
                    </Popover>
                </Overlay>
                {child}
            </>
        );
    }

    onChildClick = () =>
    {
        if(this.state.overlayVisible)
            this.updateVisibility(false, false);
        else
            this.updateVisibility(true, false);
    };

    updateVisibility = (hoverChild, hoverOverlay) =>
    {
        if(this.state.hoverChild === hoverChild && this.state.hoverOverlay === hoverOverlay)
            return;

        this.setState({ hoverChild, hoverOverlay }, () =>
        {
            clearTimeout(this.setTimeoutConst);

            const overlayVisible = hoverChild || hoverOverlay;
            if(overlayVisible === this.state.overlayVisible)
                return;

            const { onShow, onHide } = this.props;

            this.setTimeoutConst = setTimeout(() =>
            {
                this.setState({ overlayVisible }, () =>
                {
                    if(overlayVisible)
                    {
                        if(onShow)
                            onShow();
                    }
                    else
                    {
                        if(onHide)
                            onHide();
                    }
                });
            }, overlayVisible ? this._delay.show : this._delay.hide);
        });
    };

    static normalizeDelay = delay =>
    {
        return delay && typeof delay === "object" ? delay : { show: delay, hide: delay };
    };
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

OverlaySticky.defaultProps =
{
    delay: { show: 100, hide: 200 }
};

OverlaySticky.propTypes =
{
    children: PropTypes.element.isRequired,
    overlay: PropTypes.node.isRequired,
    delay: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({
            show: PropTypes.number,
            hide: PropTypes.number,
        }),
    ]),
    onShow: PropTypes.func,
    onHide: PropTypes.func,
    placement: PropTypes.string
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default OverlaySticky;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
