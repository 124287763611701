import localParams from "@ms/nlib/client/localParams";
import utils from "@ms/nlib/utils";
import { createSlice } from "@reduxjs/toolkit";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const sidebarGUID = "ddc207e0-754f-4c7e-875a-2cde29f01380";

const getVisible = () => localParams.getBool(sidebarGUID, true);

const setVisible = (state, visible) =>
{
    state.visible = visible;
    localParams.setBool(sidebarGUID, visible);

    return state;
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const sidebar = createSlice({
    name: "sidebar",
    initialState:
    {
        menus: {},      // Keep track of menus states (guid <-> open/close)
        visible: getVisible()
    },
    reducers:
    {
        show: (state, action) => setVisible(state, action.payload.visible),

        toggle: state => setVisible(state, !state.visible),

        setMenuOpen: (state, action) =>
        {
            const { id, open } = action.payload;

            state.menus[id] = open;
            localParams.setBool(id, open);

            //return state;
        }
    }
});

sidebar.methods =
{
    getMenuOpen: (state, id) =>
    {
        const open = state.menus[id];
        return utils.hasValue(open) ? open : localParams.getBool(id, false);
    }
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default sidebar;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
