import utils from "@ms/nlib/utils";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import {
    Form,
    ListGroup,
    Navbar,
    Nav,
    Popover
} from "react-bootstrap";
import { FaInfoCircle, FaUser, FaUserShield, FaUserNinja } from "react-icons/fa";
import { MdAccountCircle, MdMenu, MdExitToApp } from "react-icons/md";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import options, { getLocationIcon } from "../../client/options";
import helpers from "../../ui/helpers";
import ThemeContext from "../contexts/ThemeContext";
import Button from "../controls/Button";
import LoadIcon from "../controls/LoadIcon";
import { normalIcon } from "../controls/Icon";
import OverlaySticky from "../controls/OverlaySticky";
import sidebar from "./Sidebar.slice";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const IconAccount = normalIcon(MdAccountCircle);
const IconMenu = normalIcon(MdMenu);
const IconLogout = normalIcon(MdExitToApp);
const IconUser = normalIcon(FaUser);
const IconAdmin = normalIcon(FaUserShield);
const IconDeveloper = normalIcon(FaUserNinja);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const USER_ROLE_DEFAULT = 0;
const USER_ROLE_ADMIN = 1;
const USER_ROLE_DEVELOPER = 2;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const ToolbarNavbar = styled(Navbar)`
    border-bottom: 1px solid ${props => props.theme.colors.border};
`;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const UserLink = ({ icon, title }) =>
{
    return (
        <Button variant="link" style={{textDecoration: "none"}}>
            <div className="msp-display-center">
                {icon}&nbsp;{title}
            </div>
        </Button>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

UserLink.propTypes =
{
    title: PropTypes.string,
    icon: PropTypes.any
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const UserIndicator = props =>
{
    const renderUserLink = (title, link, icon) =>
    {
        // Do not use LinkContainer here to avoid "active" class injection issue into the menu
        /*
            <ListGroup.Item style={{padding: 0}}>
                <LinkContainer to={link} style={{textDecoration: "none"}}>
                    <Button variant="link">
                        <div className="msp-display-center">
                            {icon}&nbsp;{title}
                        </div>
                    </Button>
                </LinkContainer>
            </ListGroup.Item>
        */

        return (
            <ListGroup.Item style={{padding: 0}}>
                <Link to={link}>
                    <UserLink icon={icon} title={title}/>
                </Link>
            </ListGroup.Item>
        );
    };

    const tooltip = (
        <>
            <Popover.Title as="h3">{props.title}</Popover.Title>
            <Popover.Content style={{paddingLeft: 0, paddingRight: 0}}>
                <ListGroup variant="flush">
                    {renderUserLink("Account", options.links.account, <IconAccount/>)}
                    {renderUserLink("Logout", options.links.logout, <IconLogout/>)}
                </ListGroup>
            </Popover.Content>
        </>
    );

    let IndicatorIcon = null;
    switch(props.role)
    {
    case USER_ROLE_ADMIN:
        IndicatorIcon = IconAdmin;
        break;

    case USER_ROLE_DEVELOPER:
        IndicatorIcon = IconDeveloper;
        break;

    case USER_ROLE_DEFAULT:
    default:
        IndicatorIcon = IconUser;
        break;
    }

    return (
        <OverlaySticky placement="bottom" overlay={tooltip}>
            <Nav.Link style={{ paddingRight: 0 }}>
                <IndicatorIcon className={props.dark ? "text-primary" : "text-white"}/>
            </Nav.Link>
        </OverlaySticky>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

UserIndicator.propTypes =
{
    dark: PropTypes.bool,
    title: PropTypes.string,
    role: PropTypes.number
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/*
const LoginIndicator = props =>
{
    return (
        <Nav.Link style={{ paddingRight: 0 }} href={options.links.login}>
            <FaUser className="text-secondary"/>
        </Nav.Link>
    );
};
*/
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const LegendIndicator = props =>
{
    const tooltip = (
        <>
            <Popover.Title as="h3">{props.title}</Popover.Title>
            <Popover.Content style={{paddingLeft: 0, paddingRight: 0}}>
                <ListGroup variant="flush">
                    {props.content}
                </ListGroup>
            </Popover.Content>
        </>
    );

    return (
        <OverlaySticky placement="bottom" overlay={tooltip}>
            <Nav.Link style={{ paddingRight: 0 }}>
                <FaInfoCircle/>
            </Nav.Link>
        </OverlaySticky>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

LegendIndicator.propTypes =
{
    title: PropTypes.string,
    content: PropTypes.any
};

LegendIndicator.defaultProps =
{
    title: "Legend"
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const Toolbar = props =>
{
    const { isDark } = useContext(ThemeContext);

    const dark = isDark();

    let BaseIcon = props.loading ? <LoadIcon/> : props.icon;
    if(!utils.hasValue(BaseIcon))
        BaseIcon = getLocationIcon();

    BaseIcon = helpers.createReactElement(BaseIcon);

    const NavIcon = (
        <span className={props.loading ? (dark ? "text-primary" : "text-white") : null} style={{fontSize: normalIcon.size}}>
            {BaseIcon}
        </span>
    );

    const brand =
        <span
            className="msp-display-center"
            style={props.toggle ? { cursor: "pointer" } : null}
            onClick={props.toggleSidebar}>
            {props.toggle && <><IconMenu/>&nbsp;</>}{props.title}&nbsp;{NavIcon}
        </span>;

    return (
        <ToolbarNavbar collapseOnSelect sticky="top" bg={dark ? "dark" : "secondary"} variant={"dark"} expand="*">
            <Navbar.Brand className="msp-disable-selection">{brand}</Navbar.Brand>
            <Form inline>
                { props.commands }
                { props.legend && <LegendIndicator content={props.legend}/> }
            </Form>
        </ToolbarNavbar>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

Toolbar.propTypes =
{
    icon: PropTypes.any,
    title: PropTypes.string,
    commands: PropTypes.any,
    legend: PropTypes.any,
    loading: PropTypes.bool,
    toggle: PropTypes.bool,
    toggleSidebar: PropTypes.func.isRequired
};

Toolbar.defaultProps =
{
    title: "MS",
    toggle: true
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const mapDispatchToProps = dispatch => (
    {
        toggleSidebar: () => dispatch(sidebar.actions.toggle())
    }
);

export default connect(null, mapDispatchToProps)(Toolbar);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
