import React from "react";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const ThemeContext = React.createContext();

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default ThemeContext;

const ThemeProvider = ThemeContext.Provider;
const ThemeConsumer = ThemeContext.Consumer;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// eslint-disable-next-line react/display-name
const withTheme = Component => props =>
{
    return (
        <ThemeConsumer>
            {
                context => <Component {...props} theme={context}/>
            }
        </ThemeConsumer>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export { ThemeProvider, ThemeConsumer, withTheme };

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
