import React from "react";
import { renderRoutes } from "react-router-config";
import { Redirect } from "react-router-dom";

import options from "../client/options";
import JPKLicensesSearch from "./pages/jpk/LicensesSearch";
import NotFound from "./pages/NotFound";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const Main = props => renderRoutes(props.route.routes, props);
const DefaultRedirect = props => <Redirect to={options.links.jpk.licenses_search} />;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const routes =
    [
        {
            component: Main,
            routes: [
                {
                    path: options.links.home,
                    exact: true,
                    component: DefaultRedirect
                },
                {
                    path: options.links.jpk.licenses_search,
                    component: JPKLicensesSearch
                },
                {
                    component: NotFound
                }
            ]
        }
    ];

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default routes;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
