import PropTypes from "prop-types";
import React from "react";

import { RiLoader3Line } from "react-icons/ri";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// TODO: https://react-bootstrap.github.io/components/spinners/

const LoadIcon = props =>
{
    const { className, ...other } = props;
    return <RiLoader3Line className={className} {...other}/>;
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

LoadIcon.propTypes =
{
    className: PropTypes.string
};

LoadIcon.defaultProps =
{
    className: "msp-spin"
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default LoadIcon;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
