import PropTypes from "prop-types";
import React from "react";
import {
    FormControl,
    InputGroup
} from "react-bootstrap";
import { MdDelete } from "react-icons/md";

import helpers from "../helpers";
import { normalIcon } from "./Icon";
import ToolbarButton from "./ToolbarButton";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const DeleteIcon = normalIcon(MdDelete);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const ToolbarSearch = props =>
{
    const { disabled, empty, placeholder, inputRef, type, value, onChange, onClear } = props;

    // 1px button padding fix
    const buttonStyle = { paddingTop: "revert", paddingBottom: "revert" };

    return (
        <InputGroup>
            <InputGroup.Prepend>
                <ToolbarButton
                    onClick={onClear}
                    disabled={disabled || empty}
                    style={buttonStyle}>
                    <DeleteIcon/>
                </ToolbarButton>
            </InputGroup.Prepend>
            <FormControl
                ref={inputRef}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                value={value}
                onKeyDown={e => helpers.mapKeyEsc(e, onClear)}
                onChange={onChange}/>
        </InputGroup>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

ToolbarSearch.propTypes =
{
    disabled: PropTypes.bool,
    empty: PropTypes.bool,
    placeholder: PropTypes.string,
    inputRef: PropTypes.any,            // Do not refactor to "ref" or React internally would map the property to ToolbarSearch itself
    type: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onClear: PropTypes.func
};

ToolbarSearch.defaultProps =
{
    type: "text"
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default ToolbarSearch;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
