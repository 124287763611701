import React from "react";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const UIContext = React.createContext();

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default UIContext;

const UIProvider = UIContext.Provider;
const UIConsumer = UIContext.Consumer;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// eslint-disable-next-line react/display-name
const withUI = Component => props =>
{
    return (
        <UIConsumer>
            {
                context => <Component {...props} ui={context}/>
            }
        </UIConsumer>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export { UIProvider, UIConsumer, withUI };

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
