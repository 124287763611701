import scss from "../styles/index.scss";

import utils from "@ms/nlib/utils";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { ThemeProvider as Theme } from "styled-components";

import mss_constants from "../../common/mss_constants";
import { ThemeProvider } from "../contexts/ThemeContext";
import SessionContext from "../contexts/SessionContext";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const createTheme = style =>
{
    let theme = {
        colors: {
            border: scss.mss_colors_primary,
            primary: scss.mss_colors_primary,
            secondary: scss.mss_colors_secondary,

            background: scss.mss_theme_light_background,
            dark: scss.mss_theme_light_dark,
            softdark: scss.mss_theme_light_softdark,

            dialog_title: scss.mss_theme_light_title,
            dialog_background: scss.mss_theme_light_background,

            sidebar_background: scss.mss_colors_secondary
        }
    };

    if(style === mss_constants.themes.theme_dark)
    {
        theme.colors = utils.merge(theme.colors, {
            background: scss.mss_theme_dark_background,
            dark: scss.mss_theme_dark_dark,
            softdark: scss.mss_theme_dark_softdark,

            dialog_title: scss.mss_theme_dark_title,
            dialog_background: scss.mss_theme_dark_background,

            sidebar_background: scss.mss_theme_dark_dark
        });
    }

    return theme;
};

const useThemeContext = () =>
{
    const { getOption, setOption } = useContext(SessionContext);

    const getTheme = () => getOption(mss_constants.options.option_theme, mss_constants.themes.theme_dark);
    const setTheme = (theme, params = null) => setOption(mss_constants.options.option_theme, theme, params);
    const isDark = () => getTheme() === mss_constants.themes.theme_dark;

    return {
        theme: createTheme(getTheme()),
        getTheme,
        setTheme,
        isDark
    };
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const ThemeManager = props =>
{
    const themeContext = useThemeContext();

    return (
        <ThemeProvider value={themeContext}>
            <Theme theme={themeContext.theme}>
                {props.children}
            </Theme>
        </ThemeProvider>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

ThemeManager.propTypes =
{
    user: PropTypes.object,
    children: PropTypes.any,
    onRefresh: PropTypes.func
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default ThemeManager;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
