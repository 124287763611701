import utils from "@ms/nlib/utils";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Form, FormControl } from "react-bootstrap";

import { UIProvider } from "../contexts/UIContext";
import Button from "../controls/Button";
import Modal from "../controls/Modal";
import helpers from "../helpers";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

let g_alertDialogState = null;
let g_alertDialogStateHidden = {
    show: false,
    title: null,
    message: null,
    variant: "primary",
    onClose: null
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

let g_confirmDialogState = null;
let g_confirmDialogStateHidden = {
    show: false,
    title: null,
    message: null,
    variant: "primary",
    onConfirm: null,
    onCancel: null
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

let g_inputDialogState = null;
let g_inputDialogStateHidden = {
    show: false,
    title: null,
    message: null,
    placeholder: null,
    value: "",
    handleChange: null,         // value callback handler
    validate: null,             // value callback validator
    variant: "primary",
    onConfirm: null,
    onCancel: null
};
let g_inputDialogControl = null;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const uiContext = {
    alert: data => new Promise((resolve, reject) =>
    {
        const dialogState = Object.assign({}, g_alertDialogStateHidden);
        dialogState.show = true;
        dialogState.onClose = () => resolve();
        if(utils.isString(data))
        {
            dialogState.message = data;
        }
        else
        {
            dialogState.title = data?.title;
            dialogState.message = utils.toString(data?.message);

            if(utils.hasValue(data.variant))
                dialogState.variant = data.variant;
        }

        if(!utils.hasValue(g_alertDialogState))
        {
            reject();
            return;
        }

        g_alertDialogState[1](dialogState);
    }),

    confirm: (data, handleCancel = false) => new Promise((resolve, reject) =>
    {
        const dialogState = Object.assign({}, g_confirmDialogStateHidden);
        dialogState.show = true;
        dialogState.onConfirm = () => resolve();
        dialogState.onCancel = () =>
        {
            // TODO: figure out a better implementation to get rid of the "handleCancel" param, right now it solves the error "Uncaught (in promise)"
            // when the caller doesn't implement a cancel handler

            if(handleCancel)
                reject();
        };

        if(utils.isString(data))
        {
            dialogState.message = data;
        }
        else
        {
            dialogState.title = data?.title;
            dialogState.message = utils.toString(data?.message);

            if(utils.hasValue(data.variant))
                dialogState.variant = data.variant;
        }

        if(!utils.hasValue(g_confirmDialogState))
        {
            reject();
            return;
        }

        g_confirmDialogState[1](dialogState);
    }),

    input: data => new Promise((resolve, reject) =>
    {
        const dialogState = Object.assign({}, g_inputDialogStateHidden);
        dialogState.show = true;
        dialogState.onConfirm = value => resolve(value);
        dialogState.onCancel = () => reject();
        if(utils.isString(data))
        {
            dialogState.message = data;
        }
        else
        {
            dialogState.title = data?.title;
            dialogState.message = utils.toString(data?.message);
            dialogState.placeholder = data?.placeholder;
            dialogState.value = utils.toString(data?.value);
            dialogState.handleChange = data?.handleChange;
            dialogState.validate = data?.validate;

            if(utils.hasValue(data.variant))
                dialogState.variant = data.variant;
        }

        if(!utils.hasValue(g_inputDialogState))
        {
            reject();
            return;
        }

        g_inputDialogState[1](dialogState);
    })
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const AlertDialog = () =>
{
    g_alertDialogState = useState(g_alertDialogStateHidden);
    const [alertDialogState, setAlertDialogState] = g_alertDialogState;

    const handleClose = () =>
    {
        if(alertDialogState.onClose)
            alertDialogState.onClose();

        setAlertDialogState(g_alertDialogStateHidden);
    };

    return (
        <Modal show={alertDialogState.show} onHide={handleClose}>
            {
                alertDialogState.title &&
                <Modal.Header>
                    <Modal.Title>{alertDialogState.title}</Modal.Title>
                </Modal.Header>
            }
            <Modal.Body>{alertDialogState.message}</Modal.Body>
            <Modal.Footer>
                <Button variant={alertDialogState.variant} onClick={handleClose}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const ConfirmDialog = () =>
{
    g_confirmDialogState = useState(g_confirmDialogStateHidden);
    const [confirmDialogState, setConfirmDialogState] = g_confirmDialogState;

    const handleClose = ok =>
    {
        const callback = ok ? confirmDialogState.onConfirm : confirmDialogState.onCancel;
        if(callback)
            callback();

        setConfirmDialogState(g_confirmDialogStateHidden);
    };

    return (
        <Modal show={confirmDialogState.show} onHide={() => handleClose(false)}>
            {
                confirmDialogState.title &&
                <Modal.Header>
                    <Modal.Title>{confirmDialogState.title}</Modal.Title>
                </Modal.Header>
            }
            <Modal.Body>{confirmDialogState.message}</Modal.Body>
            <Modal.Footer>
                <Button variant={confirmDialogState.variant} onClick={() => handleClose(true)}>
                    Yes
                </Button>
                <Button variant="secondary" onClick={() => handleClose(false)}>
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const InputDialog = () =>
{
    g_inputDialogState = useState(g_inputDialogStateHidden);
    const [error, setError] = useState(null);
    const [inputDialogState, setInputDialogState] = g_inputDialogState;

    const handleClose = ok =>
    {
        const callback = ok ? inputDialogState.onConfirm : inputDialogState.onCancel;
        if(callback)
            callback(ok ? inputDialogState.value : undefined);

        setInputDialogState(g_inputDialogStateHidden);
    };

    const handleValueChanged = e =>
    {
        const value = utils.hasValue(inputDialogState.handleChange) ? inputDialogState.handleChange(e.target.value) : e.target.value;
        setError(!utils.isStringEmpty(value) && utils.hasValue(inputDialogState.validate) ? inputDialogState.validate(value) : null);

        setInputDialogState(utils.merge(inputDialogState, { value }));
    };

    useEffect(() => {
        if(utils.hasValue(g_inputDialogControl) && (document.activeElement !== g_inputDialogControl))
        {
            g_inputDialogControl.focus();
            g_inputDialogControl.select();
        }
    });

    const isEmpty = utils.isStringEmpty(inputDialogState.value);
    const hasError = utils.hasValue(error);

    return (
        <Modal show={inputDialogState.show} onHide={() => handleClose(false)}>
            {
                inputDialogState.title &&
                <Modal.Header>
                    <Modal.Title>{inputDialogState.title}</Modal.Title>
                </Modal.Header>
            }
            <Modal.Body>
                <Form.Group>
                    {inputDialogState.message && <Form.Label>{inputDialogState.message}</Form.Label>}
                    <FormControl
                        isInvalid={hasError}
                        ref={input => g_inputDialogControl = input }
                        value={inputDialogState.value}
                        placeholder={inputDialogState.placeholder}
                        onKeyDown={e => helpers.mapEnterKey(e, () => handleClose(true))}
                        onChange={handleValueChanged}/>
                    {hasError ? <FormControl.Feedback type="invalid">{error}</FormControl.Feedback> : null}
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={isEmpty || hasError} variant={inputDialogState.variant} onClick={() => handleClose(true)}>
                    OK
                </Button>
                <Button variant="secondary" onClick={() => handleClose(false)}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const UIManager = props =>
{
    return (
        <UIProvider value={uiContext}>
            {props.children}

            <AlertDialog/>
            <ConfirmDialog/>
            <InputDialog/>
        </UIProvider>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

UIManager.propTypes =
{
    children: PropTypes.any
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default UIManager;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
