import utils from "@ms/nlib/utils";
import React from "react";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const mapIcon = size =>
{
    // eslint-disable-next-line react/display-name
    const mapped = Icon => props => utils.hasValue(Icon) ? <Icon {...props} size={`${size}px`}/> : null;
    mapped.size = size;
    return mapped;
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const largeIcon = mapIcon(26);
const normalIcon = mapIcon(20);
const smallIcon = mapIcon(12);
const tinyIcon = mapIcon(8);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export { largeIcon, normalIcon, smallIcon, tinyIcon };

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
