import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "../ui/App";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const ClientApp = () => (
    <BrowserRouter>
        <App/>
    </BrowserRouter>
);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const renderApp = () =>
{
    ReactDOM.render(<ClientApp/>, document.getElementById("root"));
};

const run = () =>
{
    renderApp();

    //if(module.hot)
    //  module.hot.accept("../ui/App", renderApp);
    if(module.hot)
        module.hot.accept();
};

run();

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
