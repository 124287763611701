import PropTypes from "prop-types";
import React from "react";
import { Modal as BootstrapModal } from "react-bootstrap";
import styled from "styled-components";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const StyledModal = styled(BootstrapModal)`
    & .modal-header
    {
        border-bottom-color: ${props => props.theme.colors.primary};
    }

    & .modal-header,
    & .modal-header .close
    {
        color: ${props => props.theme.colors.dialog_title};
        background-color: transparent !important;	
        text-shadow: 0 1px 0 #000;
        opacity: 1;
    }

    & .modal-body
    {
        color: ${props => props.theme.colors.dialog_title};
    }

    & .modal-content
    {
        background-color: ${props => props.theme.colors.dialog_background};
        color: white;	
    }

    & .modal-footer
    {
        border-top-color: ${props => props.theme.colors.primary};
    }
`;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const Modal = props => <StyledModal {...props}/>;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

Modal.Dialog = BootstrapModal.Dialog;
Modal.Header = BootstrapModal.Header;
Modal.Title = BootstrapModal.Title;
Modal.Body = BootstrapModal.Body;
Modal.Footer = BootstrapModal.Footer;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

Modal.propTypes =
{
    dialogClassName: PropTypes.string
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default Modal;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
