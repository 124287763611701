import React from "react";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const SessionContext = React.createContext();

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default SessionContext;

const SessionProvider = SessionContext.Provider;
const SessionConsumer = SessionContext.Consumer;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// eslint-disable-next-line react/display-name
const withSession = Component => props =>
{
    return (
        <SessionConsumer>
            {
                context => <Component {...props} session={context}/>
            }
        </SessionConsumer>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export { SessionProvider, SessionConsumer, withSession };

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
