import { combineReducers } from "@reduxjs/toolkit";

import sidebar from "../Sidebar.slice";
//import toolbar from "../Toolbar.slice";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default combineReducers({
    //toolbar: toolbar.reducer,
    sidebar: sidebar.reducer
});

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
