import utils from "@ms/nlib/utils";

import messages_en from "./languages/en.json";
import messages_it from "./languages/it.json";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const DEFAULT_LOCALE = "en";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const messages = {
    "en": messages_en,
    "it": messages_it
};

const loadMessages = locale =>
{
    // Remove the region code from the language
    const code = locale.split(/[-_]/)[0];
    const result = messages[code];
    // Load the messages in the specified locale if available or fallback to the default one
    return utils.hasValue(result) ? result : messages[DEFAULT_LOCALE];
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export { loadMessages };

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
