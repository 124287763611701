import utils from "@ms/nlib/utils";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { injectIntl, IntlProvider } from "react-intl";
import { useLocation } from "react-router-dom";

import options from "../../client/options";
import { loadMessages } from "../../i18n";
import { LanguageProvider } from "../contexts/LanguageContext";
import SessionContext from "../contexts/SessionContext";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const toLocale = language => utils.replaceAll(language, "_", "-");

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const LANGUAGE_SEPARATOR = ".";
const LANGUAGE_PREFIX_UI = "ui";
const LANGUAGE_PREFIX_PAGES = "pages";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const useLanguageContext = intl =>
{
    const { pathname } = useLocation();

    const format = ({ id, message = null, description = null, values = null }) => {
        const formatted = intl.formatMessage({ id, defaultMessage: message, description, values });
        return options.language.debug ? `~${formatted}~` : formatted;
    };
    const formatUI = ({ key, message = null, description = null, values = null }) =>
        format({ id: utils.join({ separator: LANGUAGE_SEPARATOR }, LANGUAGE_PREFIX_UI, key), message, description, values });
    const formatLocation = ({ key, message = null, description = null, values = null }) =>
        formatUI({
            key: utils.join({ separator: LANGUAGE_SEPARATOR }, LANGUAGE_PREFIX_PAGES, utils.replaceAll(pathname, "/", ".").substring(1), key), message, description, values
        });

    const translate = (id, message = null, description = null) => format({ id, message, description });
    const translateUI = (key, message = null, description = null) => formatUI({ key, message, description });
    const translateLocation = (key, message = null, description = null) => formatLocation({ key, message, description });

    return {
        format,
        formatUI,
        formatLocation,
        translate,
        translateUI,
        translateLocation
    };
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const LanguageWrapper = props =>
{
    return (
        <LanguageProvider value={useLanguageContext(props.intl)}>
            {props.children}
        </LanguageProvider>
    );
};

LanguageWrapper.propTypes =
{
    children: PropTypes.any,
    intl: PropTypes.any
};

const LanguageWrapperImpl = injectIntl(LanguageWrapper);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const LanguageManager = props =>
{
    const { getLanguage } = useContext(SessionContext);

    const locale = toLocale(getLanguage());
    const messages = loadMessages(locale);
    //const messages = loadMessages(mss_constants.localization.language_default);     // Force default language
    const handleError = e => utils.raiseError(e);

    return (
        <IntlProvider locale={locale} messages={messages} onError={handleError}>
            <LanguageWrapperImpl>
                {props.children}
            </LanguageWrapperImpl>
        </IntlProvider>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

LanguageManager.propTypes =
{
    children: PropTypes.any
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default LanguageManager;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
