import utils from "@ms/nlib/utils";
import PropTypes from "prop-types";
import React from "react";
import { renderRoutes } from "react-router-config";
import styled from "styled-components";

import AsyncComponent from "../components/AsyncComponent";
import LanguageManager from "../components/LanguageManager";
import NotificationsManager from "../components/NotificationsManager";
import SessionManager from "../components/SessionManager";
import ThemeManager from "../components/ThemeManager";
import UIManager from "../components/UIManager";
import LoadIndicator from "../controls/LoadIndicator";
import Content from "./Content";
import { Error } from "../controls/Message";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const Body = styled.div`
    display: flex;
    flex-direction: column;
	min-height: 100vh;
`;
//border: 10px solid ${props => props.theme.colors.background};
//transition: all 1s;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class Root extends AsyncComponent
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            error: null
        };
    }

    componentDidMount()
    {

    }

    render()
    {
        let content = null;
        if(utils.hasValue(this.state.error))
            content = <Error text={this.state.error}/>;
        else if(this.state.loading)
            content = <LoadIndicator/>;

        return (
            <SessionManager>
                <ThemeManager>
                    <LanguageManager>
                        <UIManager>
                            <NotificationsManager>
                                <Body>
                                    {content ? <Content center sidebarVisible={false}>{content}</Content> : renderRoutes(this.props.routes, this.props)}
                                </Body>
                            </NotificationsManager>
                        </UIManager>
                    </LanguageManager>
                </ThemeManager>
            </SessionManager>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

Root.propTypes =
{
    routes: PropTypes.any
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default Root;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
