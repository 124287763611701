import PropTypes from "prop-types";
import React from "react";

import {
    OverlayTrigger,
    Tooltip as BootstrapTooltip
} from "react-bootstrap";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const normalizeDelay = delay =>
{
    return delay && typeof delay === "object" ? delay : { show: delay, hide: delay };
};

const Tooltip = props =>
{
    const delay = normalizeDelay(props.delay);
    const tooltip = <BootstrapTooltip>{props.title}</BootstrapTooltip>;

    return (
        <OverlayTrigger placement={props.placement} delay={delay} overlay={tooltip}>
            {props.children}
        </OverlayTrigger>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

Tooltip.defaultProps =
{
    delay: { show: 500, hide: 250 },
    placement: "top"
};

Tooltip.propTypes =
{
    children: PropTypes.element.isRequired,
    delay: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({
            show: PropTypes.number,
            hide: PropTypes.number,
        }),
    ]),
    placement: PropTypes.string,
    title: PropTypes.any.isRequired,
    onShow: PropTypes.func,
    onHide: PropTypes.func
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default Tooltip;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
