import utils from "@ms/nlib/utils";
import PropTypes from "prop-types";
import React from "react";
import { Alert, Fade } from "react-bootstrap";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const Message = props =>
{
    let { text, type, show, fade, shadow, children, ...other} = props;

    show = show && (utils.isStringEmpty(text) === false || utils.hasValue(children));

    if(!show)
        return null;

    const content = (
        <Alert variant={type} {...other} className={shadow ? "shadow" : null}>
            {text}
            {children}
        </Alert>
    );

    if(!fade)
        return content;

    return (
        <Fade mountOnEnter appear in>
            <div>
                {content}
            </div>
        </Fade>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

Message.propTypes =
{
    children: PropTypes.any,
    text: PropTypes.string,
    type: PropTypes.string,
    show: PropTypes.bool,
    fade: PropTypes.bool,
    shadow: PropTypes.bool,
};

Message.defaultProps =
{
    text: "",
    type: "info",
    show: true,
    fade: true,
    shadow: false
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const Error = props => <Message {...props} type="danger"/>;
const Warning = props => <Message {...props} type="warning"/>;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

Message.Heading = Alert.Heading;
Error.Heading = Alert.Heading;
Warning.Heading = Alert.Heading;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export { Error, Message, Warning };

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
