import React from "react";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const LanguageContext = React.createContext();

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default LanguageContext;

const LanguageProvider = LanguageContext.Provider;
const LanguageConsumer = LanguageContext.Consumer;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// eslint-disable-next-line react/display-name
const withLanguage = Component => props =>
{
    return (
        <LanguageConsumer>
            {
                context => <Component {...props} language={context}/>
            }
        </LanguageConsumer>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export { LanguageProvider, LanguageConsumer, withLanguage };

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
